import { css, html, LitElement, TemplateResult } from 'lit';

customElements.define(
  'dg-new-session',
  class extends LitElement {
    static styles = css`
      :host {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
      }
    `;

    render(): TemplateResult {
      return html` <form action="/sessions/new" method="post">
        <button type="submit">Sign in with Google</button>
      </form>`;
    }
  }
);
